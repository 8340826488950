<template>
    <div class="text-center">
        <h3 class="mt-20 fs23">{{ $t("Cancellation of freepaid recharge") }}</h3>
        <h1 class="mt-15">{{ $t("The cancellation was partially successful") }}</h1>
        <h3 class="mt-15 fs23">הטעינה בוטלה באופן חלקי בסכום של 49 ₪ מתוך 59 ₪</h3>
        <button class="primary-btn result-btn">
            {{ $t("Closed") }}
        </button>
    </div>
</template>

<script>
export default {
    name: "CancellationPartialSuccess",
    mounted() {
        document.getElementsByTagName("html")[0].style.background = "#FFF6A5";
    },
}
</script>

<style lang="scss" scoped>
.result-btn {
    margin: 0 auto;
    margin-top: 70px;
    max-width: 400px;
}
</style>